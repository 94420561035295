<template>
  <div id="app">
    <!-- <div class="main_bg"></div> -->
    <Header />
    <template>
      <div class="ma-0 pa-3" style="overflow: hidden;">
        <v-row>
          <v-col lg="5" md="5" sm="12" cols="12">
            <v-menu
              hide-details
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date1"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date1"
                  :label="$t('home.order_date_start')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu1.save(date1)"
                v-model="date1"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col lg="5" md="5" sm="12" cols="12">
            <v-menu
              hide-details
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date2"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  :label="$t('home.order_date_end')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu2.save(date2)"
                v-model="date2"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col lg="2" md="2" sm="12" cols="12">
            <v-btn class="mt-3" @click="loadData" color="primary">
            <v-icon left>search</v-icon>
            <span>{{ $t('home.query') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        
        <v-row class="mt-n7">
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.sales')"
              avatar_ic="mdi-cart-outline"
              avatar_bg="#e235c3"
              :subtitle="getSales()"></Summary>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.orders')"
              avatar_ic="mdi-cart-outline"
              avatar_bg="#f9b6e2"
              :subtitle="getOrders()"></Summary>
          </v-col>
          <!-- <v-col lg="12" md="12" sm="12" cols="12">
            <Summary
              :title="$t('home.orderQty')"
              avatar_ic="mdi-cart-outline"
              avatar_bg="#f9b6e2"
              :subtitle="getOrderQty()"></Summary>
          </v-col> -->
        </v-row>

        <v-row class="mt-n3">
          <v-col lg="6" md="6" sm="12" cols="12">
            <!-- <v-flex v-for="i in 4" xs4> -->
              <v-data-table
                dense
                :headers="getHeaders"
                :items="saleReport?.topSalesQty ?? []"
                :hide-default-header="false"
                :hide-default-footer="true"
                :items-per-page="-1">
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="vertical-align: top;">
                      <v-img
                        contain
                        class="ma-2"
                        style="cursor: pointer"
                        :eager="true"
                        :src="getImageUrl(item)"
                        @click="showImage(item)"
                        lazy-src="@/assets/noimage.png"
                        aspect-ratio="1"
                        min-width="80"
                        max-width="80"
                        max-height="80">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align-center
                            justify="center">
                            <v-progress-circular v-show="loading"
                              indeterminate
                              color="gray lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </td>
                    <td style="vertical-align: top; padding: 5px;">{{ item.cprcode }} - {{ item.productName }}</td>
                    <td style="vertical-align: top; text-align: right; padding: 5px;">{{ numberFormat(item.qty,0) }}</td>
                    <td style="vertical-align: top; text-align: right; padding: 5px;">{{ numberFormat(item.amount,2) }}</td>
                  </tr>
                </template>
              </v-data-table>
            <!-- </v-flex> -->
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <BarChart ref='bar_chart' style="height: 250px;" :chartData="datacollection" /><br/>
            <PieChart ref='pie_chart' style="height: 350px;" :chartData="chartData" />
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.bottom_nav_1')"
              avatar_ic="mdi-file-document-multiple-outline"
              avatar_bg="#a2d2df"
              :subtitle="getQty(0)"></Summary>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.bottom_nav_2')"
              avatar_ic="mdi-file-document-refresh-outline"
              avatar_bg="#73d016"
              :subtitle="getQty(1)"></Summary>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.bottom_nav_3')"
              avatar_ic="mdi-file-document-arrow-right-outline"
              avatar_bg="#00a65a"
              :subtitle="getQty(2)"></Summary>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.bottom_nav_4')"
              avatar_ic="mdi-file-document-check-outline"
              avatar_bg="#065535"
              :subtitle="getQty(3)"></Summary>
          </v-col>
        </v-row> -->

        <!-- <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <Summary
              :title="$t('home.bottom_nav_5')"
              avatar_ic="mdi-file-document-remove-outline"
              avatar_bg="#b6b2b2"
              :subtitle="getQty(4)"></Summary>
          </v-col>
        </v-row> -->
      </div>
    </template>
    <Bottom />
    <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay>
    <ShowMessage 
      v-model="isShowMessage" 
      :msg="showmessage_msg"
      :width="showmessage_width"
      :callBack="showmessage_callback">
    </ShowMessage>
    <ShowImage 
      v-model="isShowImage" 
      :msg="showimage_msg"
      :imageName="showimage_image_name"
      :width="showimage_width"
      :callBack="showimage_callback">
    </ShowImage>
  </div>
</template>

<script>
import { imageUrl } from "@/services/constants";
import Header from "@/components/Header"
import Bottom from "@/components/Bottom"
import ShowOverlay from "@/components/ShowOverlay";
import ShowMessage from "@/components/ShowMessage";
import ShowImage from "@/components/ShowImage";
// import ShowConfirm from "@/components/ShowConfirm";
import Summary from "@/components/cards/Summary";
import BarChart from '@/components/charts/BarChart'
import PieChart from '@/components/charts/PieChart'
import api from "@/services/api";
import moment from 'moment';

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      isShowOverlay: false,

      isShowMessage: false,
      showmessage_msg: "",
      showmessage_width: 400,
      showmessage_callback: null,

      isShowImage: false,
      showimage_msg: "",
      showimage_image_name: "",
      showimage_width: 400,
      showimage_callback: null,

      //BarChart
      mAmountArray: [],
      mTicketArray: [],
      datacollection: {},
      chartData: null,

      date1: null, // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      date2: null, // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,
      saleReport: null,
      // headers: [
      //   {
      //     text: "รูปภาพ",
      //     align: "left",
      //     sortable: false,
      //     value: "image"
      //   },
      //   { text: "ชื่อสินค้า", value: "productName" },
      //   { text: "จำนวน", value: "qty", align: "right" },
      //   { text: "ยอดขาย", value: "amount", align: "right" },
      // ],
    };
  },
  components: {
    Header,
    Bottom,
    ShowOverlay,
    ShowMessage,
    ShowImage,
    Summary,
    BarChart,
    PieChart
  },
  async mounted() {
    let sdate = this.$store.state.startDate;
    let edate = this.$store.state.endDate;
    if (!sdate || sdate == null || sdate == "") {
      this.date1 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.date1 = moment(this.date1).subtract(6, 'days').format('YYYY-MM-DD');
      this.date2 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    }
    else {
      this.date1 = sdate;
      this.date2 = edate;
    }
    await this.loadData();
  },
  computed: {
    // getQtyLabel() {
    //   // this.$refs.bar_chart.render();
    //   return this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_qty;
    // },
    // getSalesLabel() {
    //   // this.$refs.bar_chart.render();
    //   return this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_amount;
    // },
    getHeaders() {
      return [
        {
          text: this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_image,
          align: "left",
          sortable: false,
          value: "image"
        },
        { text: this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_productName, value: "productName" },
        { text: this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_qty, value: "qty", align: "right" },
        { text: this.$i18n.getLocaleMessage(this.$i18n.locale).home.grid_amount, value: "amount", align: "right" },
      ]
    },
  },
  methods: {
    showMessage(showmessage_msg, showmessage_width) {
      this.showmessage_msg = showmessage_msg;
      this.showmessage_width = showmessage_width;
      this.isShowMessage = true;
    },
    showImage(item) {
      this.showimage_callback = null;
      this.showimage_msg = item.productName;
      this.showimage_image_name = item.image;
      this.showimage_width = 400;
      this.isShowImage = true;
    },
    getSales() {
      return this.numberFormat(this.saleReport?.amount ?? 0, 2);
    },
    getOrders() {
      return this.numberFormat(this.saleReport?.count_order ?? 0, 0);
    },
    getOrderQty() {
      return this.numberFormat(this.saleReport?.count ?? 0, 0);
    },
    getQty(deliveryStatus) {
      let mRow = this.saleReport?.deliveryStatus?.find(d => d.deliveryStatus == deliveryStatus);
      if (mRow) return this.numberFormat(mRow?.count ?? 0, 0);
      return "0";
    },
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
    getImageUrl(item) {
      return `${imageUrl}/${item.image}`;
    },
    async mapData() {
      if (this.saleReport?.topSalesQty) {
        var tickets = []
        var amounts = []
        var chartKeys = []
        var chartValues = []
        this.saleReport?.topSalesQty.forEach(item => {
          tickets.push(item["qty"] ?? 0);
          amounts.push(item["amount"] ?? 0);
          chartKeys.push((item["productName"] ?? "").substr(0,40));
          chartValues.push(item["amount"]);
        });
        this.mTicketArray = tickets;
        this.mAmountArray = amounts;

        this.chartData = {
          labels: chartKeys,
          datasets: [
            {
              backgroundColor: ['#41B883', '#fbcd07', '#5b3d7a', '#f08080', '#3f4a99'],
              data: chartValues
            }
          ]
        };
      }
    },
    async loadData() {
      try {
        this.showmessage_callback = null;
        this.isShowOverlay = true;
        let sdate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
        let edate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');
        await this.$store.dispatch("setStartDate", sdate);
        await this.$store.dispatch("setEndDate", edate);
        this.saleReport = await api.getVendorSaleReport(sdate, edate);
        await this.mapData();
        await this.fillData();
      } catch (error) {
        this.showMessage(error.response.data, 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 100);
      }
    },
    async fillData () {
      let labels = [];
      this.saleReport?.topSalesQty.forEach(item => {
        // labels.push(item.cprcode.toString().concat(" - ").concat(item.productName));
        labels.push(item.productName.substr(0, 20));
        // labels.push(item.cprcode);
      });
      this.datacollection = {
          //Data to be represented on x-axis
        labels: labels,
        datasets: [
          {
            type: 'bar',
            label: 'ยอดขาย',
            pointBackgroundColor: 'white',
            borderWidth: 2,
            pointBorderColor: '#249EBF',
            data: this.getRevenues(),
            borderColor: 'transparent',
            // borderColor: [
            //   '#43A047'
            // ],
            yAxisID: '0',
            backgroundColor: '#3D5B96',
            order: 2,
          },
          {
            type: 'line',
            label: 'จำนวน',
            pointBackgroundColor: 'white',
            borderWidth: 2,
            pointBorderColor: '#249EBF',
            data: this.getTickets(),
            borderColor: '#03A9F4',
            // borderColor: [
            //   '#3F51B5'
            // ],
            yAxisID: '1',
            backgroundColor: [ 'transparent' ],
            order: 1,
          },
        ]};
    },
    getRevenues() {
      return this.mAmountArray;
    },
    getTickets() {
      return this.mTicketArray;
    },
  },
}
</script>

<style scoped>
.v-btn {
  text-transform:none !important;
}
</style>