<template>
  <div class="app">
    <div class="title_bg pa-2">{{title}}</div>
    <div class="title pa-2">{{subtitle}}</div>
    <!-- <v-card ripple id="Summary">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">{{title}}</v-list-item-title>
          <v-list-item-subtitle class="title">{{subtitle}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80" :color="avatar_bg">
          <v-icon color="white"> {{avatar_ic}} </v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-card> -->
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: ["title", "subtitle", "avatar_bg", "avatar_ic"]
};
</script>

<style scoped>
.title_bg {
    /* background-color: #303F9F; */
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    font-size: 0.9rem !important;
}
/* .headline {
  background-color: #303f9f;
  color: #ffffff;
} */
.title {
  background-color: #fbfcd5;
  color: #303f9f;
}
</style>