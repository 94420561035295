<script>
import { Pie, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  components: {
    ChartDataLabels,
  },
  data() {
    return {
      options: {
        // tooltips: {
        //   enabled: false
        // },
        legend: {
          position: 'bottom',
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              const datapoints = ctx.chart.data.datasets[0].data
              const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
              const percentage = (value / total) * 100
              return percentage.toFixed(0) + "%";
            },
            color: '#f3f6f4',
          }
        }
      }
    }
  }, 
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // alert(JSON.stringify(this.chartData));
    // alert(JSON.stringify(this.options));
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options)
  },
  props: {
    chartData: null,
  },
}
</script>

<style>
</style>